import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import EditIcon from '@atlaskit/icon/glyph/edit';
import gherkin from 'react-syntax-highlighter/dist/esm/languages/hljs/gherkin';
import markdown from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
import vs from 'react-syntax-highlighter/dist/esm/styles/hljs/vs';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// hooks
import { useHistory, useParams } from 'react-router-dom';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import ToolTip from '@atlaskit/tooltip';
import useTestomatioFetch from '../hooks/useTestomatioFetch';

// components
import If from '../components/If';
import useModel from '../hooks/useModel';
import { useStore } from '../store';
import Branches from '../components/Branches';
import useRevision from '../hooks/useRevision';
import IfLoggedIn from '../components/IfLoggedIn';
import Project from '../components/Project';

SyntaxHighlighter.registerLanguage('gherkin', gherkin);
SyntaxHighlighter.registerLanguage('markdown', markdown);

const ViewTest = observer(() => {
  useModel('project', 'issue');
  const { id, branch, slug } = useParams();
  const history = useHistory();
  const { user, jira } = useStore();
  const [test, setTest] = useState(null);
  const [testError, setTestError] = useState(false);
  const [revision, reloadModel] = useRevision();
  const testFetch = useTestomatioFetch(
    `/api/${slug}/tests/${id}?branch=${branch}`,
    {
      method: 'GET',
    },
    [revision],
  );

  useEffect(() => {
    if (user.isLoggedIn && !user.hasRestrictedAccess) return;
    if (!jira.currentIssue || !id) return;
    const loadTest = async () => {
      const response = await jira.getTestDetail({ id, branch_slug: branch });
      if (response) setTest(response);
    };
    loadTest();
  }, [id, branch, jira, jira.currentIssue, user.isLoggedIn, user.hasRestrictedAccess]);

  useEffect(() => {
    const { response } = testFetch;
    if (!testFetch.response) return;
    // setTestId(id)
    if (response) {
      setTest({ ...response.attributes, id: response.id });
    } else {
      setTestError(true);
    }
    // eslint-disable-next-line
  }, [testFetch.response]);

  if (testError && typeof testError !== 'string') {
    return (
      <div className="text-center mt-4">
        <h4 className="p-6">
          Test can&apos;t be found. Probably it was removed, detached from
          issue, or belongs to a different project.
        </h4>
        <div className="actions space-x-2">
          <Button appearance="primary" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      </div>
    );
  }

  if (testFetch.error) {
    return (
      <div className="empty">
        Test can&rsquo;t be found. Maybe it is in another branch?
        <div className="my-4 flex justify-center space-x-2">
          <Button onClick={() => history.goBack()}>Back</Button>
          <If condition={!jira.showAllProjects}>
            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches onChange={reloadModel} />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches onChange={reloadModel} />
            </If>
          </If>
        </div>
      </div>
    );
  }

  if (!test) {
    return (
      <div className="flex justify-center mt-32">
        <Spinner size="large" />
      </div>
    );
  }

  function urlHandler(text) {
    const regexp = /(?<=\]\()\/[^)]+(?=\))/g;

    const backendUrl = jira.testomatioURL || '';

    return text?.replace(regexp, (match) => `${backendUrl}${match}`) || '';
  }

  return (
    <div>
      <div className="flex mb-4 py-2 px-2 items-center shadow-sm justify-between">
        <h3>{test.title}</h3>
        <div className="flex">
          <If condition={!jira.showAllProjects}>
            <Project />
            <If condition={jira.branch?.title.length > 50}>
              <ToolTip content={jira.branch?.title} truncate>
                <Branches />
              </ToolTip>
            </If>
            <If condition={jira.branch?.title.length < 51 || !jira.branch}>
              <Branches />
            </If>
          </If>
        </div>
        <div className="actions flex space-x-2 items-center">
          <IfLoggedIn showRestrictedUser>
            <If condition={!jira.showAllProjects}>
              <Button
                iconBefore={(iconProps) => <EditIcon {...iconProps} size="small" />}
                appearance="default"
                onClick={() => history.replace(`/edit_test/${slug}/${branch}/${id}`)}
              >
                Edit
              </Button>
            </If>
          </IfLoggedIn>
          <Button appearance="default" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      </div>
      <If condition={!test.description && !test.code}>
        <div className="empty">
          Description is empty or it was not loaded.
          {!user.isLoggedIn && <span>Log in to see the latest version</span>}
        </div>
      </If>
      <If condition={jira.isGherkin && !!test.description}>
        <SyntaxHighlighter language="gherkin" style={vs}>
          {test.description}
        </SyntaxHighlighter>
      </If>
      <If condition={jira.isGherkin && !test.description && !!test.code}>
        <SyntaxHighlighter language="gherkin" style={vs}>
          {test.code}
        </SyntaxHighlighter>
      </If>
      <If condition={!jira.isGherkin && !!test.description}>
        <div className="markdown">
          <Markdown remarkPlugins={[remarkGfm]}>{urlHandler(test.description)}</Markdown>
        </div>
      </If>
      <If condition={!jira.isGherkin && !test.description && !!test.code}>
        <SyntaxHighlighter language="markdown" style={vs}>
          {test.code}
        </SyntaxHighlighter>
      </If>
    </div>
  );
});

export default ViewTest;
