import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SignInIcon from '@atlaskit/icon/glyph/sign-in';
import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import User from './User';
import { useStore } from '../store';

const IfLoggedIn = observer((props) => {
  const store = useStore();
  const { user, jira } = store;
  const history = useHistory();
  const { children } = props;
  if (user.currentUser && user.hasRestrictedAccess) {
    if (!props.showRestrictedUser) return <></>;
    return (
      <div className="flex bg-orange-100 items-center space-x-2">
        <span className="px-2 text-orange-400 text-xs">
          Read-Only
        </span>
        <User />
      </div>
    );
  }

  if (!jira.subscription) {
    if (!props.showRestrictedUser) return <></>;
    return (
      <div className="flex bg-yellow-100 items-center space-x-2">
        <a href={`${jira.testomatioURL}/company_subscriptions/new`} target="_blank" rel="noopener noreferrer" className="px-2 text-yellow-600 text-xs">
          Upgrade Needed
        </a>
        <User />
      </div>
    );
  }
  if (user.isLoggedIn && !user.hasRestrictedAccess) {
    return <>{children}</>;
  }
  if (props.showDisabled) {
    return (
      <Tooltip content="Login is required">
        <span className="opacity-25 whitespace-no-wrap">{children}</span>
      </Tooltip>
    );
  }
  //             {!user.isLoggedIn && (<Button iconBefore={<StarFilledIcon size="small" /> onClick={() => history.replace('/login')}>Login</Button>)}

  return (
    <Button
      iconBefore={(iconProps) => <SignInIcon {...iconProps} size="small" />}
      onClick={() => history.replace('/login')}
    >
      Login
    </Button>
  );
});

export default IfLoggedIn;

IfLoggedIn.propTypes = {
  children: PropTypes.node.isRequired,
  showDisabled: PropTypes.any.isOptional,
};
